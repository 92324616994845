<template>
    <div class="mainPart sn_table_group">
        <div class="tabPart flex">
            <div
                v-for="(item, index) in typeList"
                :key="index"
                class="tabItem pointer"
                :class="[item.id == typeId ? 'tabItemActive' : '']"
                @click="changeTab(item)"
            >
                {{ item.name }}
            </div>
        </div>
        <!-- 搜索部分 -->
        <div class="searchPart flex-between fxpgBgglSearch">
            <div class="flex-y-center">
                <div>关键字</div>
                <el-input
                    v-model="keyword"
                    placeholder="请输入关键字"
                    style="width: 160px"
                    class="searchItem"
                ></el-input>
                <div>监测区域</div>
                <el-select
                    v-model="jcqy"
                    placeholder="请选择监测区域"
                    class="searchItem"
                    @change="getBhbhList()"
                >
                    <el-option
                        v-for="(item, index) in jcqyList"
                        :key="index"
                        :label="item.JCQY"
                        :value="item.JCQY"
                    >
                    </el-option>
                </el-select>
                <div>病害编号</div>
                <el-select
                    v-model="bhbh"
                    placeholder="请选择病害编号"
                    class="searchItem"
                    @change="getMainData()"
                >
                    <el-option
                        v-for="(item, index) in bhbhList"
                        :key="index"
                        :label="item.BHBH"
                        :value="item.BHBH"
                    >
                    </el-option>
                </el-select>
                <el-button
                    size="small"
                    round
                    style="
                background: rgb(42, 99, 213);
                color: rgb(255, 255, 255);
                border-color: rgb(42, 99, 213);
                box-shadow: rgb(42, 99, 213) 0px 1px 6px;
        }"
                    @click="getMainData()"
                >
                    查看
                </el-button>
            </div>

            <el-button
                size="small"
                round
                style="
                    background: rgb(255, 144, 102);
                    color: rgb(255, 255, 255);
                    border-color: rgb(255, 144, 102);
                    box-shadow: rgb(255, 144, 102) 0px 1px 6px;
                "
                @click="back()"
            >
                返回
            </el-button>
            <!-- <sn-search
        class="sn-search clearfix"
        :snSearch.sync="snSearch"
        @handleChange="handleChange"
        style="height:40px;"
      ></sn-search> -->
        </div>
        <div class="actionPart flex-between-y-center">
            <sn-num
                class="sn-num"
                :snNum.sync="snPage"
                @handleChange="handleChange"
            ></sn-num>
            <div>
                <!-- :commonMethods="commonMethods" -->
                <sn-button
                    class="sn-button"
                    :snButton.sync="snButton"
                    @handleChange="handleChange"
                    style="margin-right: 10px"
                ></sn-button>
                <sn-count
                    class="sn-count"
                    @handleChange="handleChange"
                ></sn-count>
            </div>
        </div>

        <div class="titlePart">
            <div class="top">建设单位：{{ jsdw }}</div>
            <div class="bottom flex-between">
                <div class="bottomItem flex-1">仪器类型：{{ yqlx }}</div>
                <div class="bottomItem flex-1">观察者：{{ gcz }}</div>
                <div class="bottomItem flex-1">计算者：{{ jsz }}</div>
                <div class="bottomItem flex-1">检查者：{{ jcz }}</div>
            </div>
        </div>

        <div class="tablePart sn_tablebody">
            <sn-load v-show="loading"></sn-load>

            <!-- :commonMethods="commonMethods" -->
            <!-- :exp.sync="exp" -->
            <!--  @getTableData="$emit('getTableData')" -->
            <sn-table
                v-show="!loading"
                ref="snTable"
                :snTable.sync="snTable"
                :boxHeight.sync="boxHeight"
                @handleChange="handleChange"
            >
                <!-- <template slot="exp">
          <slot name="exp"></slot>
        </template> -->
            </sn-table>
            <sn-page
                v-show="!loading"
                :snPage.sync="snPage"
                @handleChange="handleChange"
            ></sn-page>
        </div>
        <sn-dialog
            :dialogData="dialogData"
            @closeDialog="closeDialog"
            @onSubmit="onSubmitFile()"
        >
            <el-upload
                ref="file_single"
                drag
                :action="`${BASE_URL}ImportChbgExcel/ImportExcel`"
                :file-list="fileList"
                :data="{
                    bgid: $route.query.bgid,
                    lx: typeId,
                }"
                :limit="1"
                :on-success="successUpload"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                    将文件拖到此处，或
                    <em>点击新增</em>
                </div>
            </el-upload>
        </sn-dialog>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "",
    data() {
        return {
            BASE_URL: window.REQUEST_URL,
            loading: false,
            boxHeight: "500",
            snTable: {
                table: {
                    data: [],
                    rowKey: "ID",
                },
                columns: {
                    // 选择、序号
                    columnsOperate: [
                        // {
                        //   isShow: false,
                        //   type: "selection",
                        //   width: 35
                        // },
                        // {
                        //   isShow: false,
                        //   label: "序号",
                        //   type: "index",
                        //   width: 50
                        // }
                    ],
                    columnsData: [
                        {
                            label: "监测区域",
                            prop: "JCQY",
                        },
                        {
                            label: "病害名称",
                            prop: "SJBHMC",
                        },
                        {
                            label: "测点编号",
                            prop: "CDBH",
                        },
                        {
                            label: "监测时间",
                            prop: "GCSJ",
                        },
                        {
                            label: "本期观测值（m）",
                            prop: "BQGCZ",
                        },
                        {
                            label: "上期观测值（m）",
                            prop: "SQGCZ",
                        },
                        {
                            label: "初始观测值（m）",
                            prop: "CSGCZ",
                        },
                        {
                            label: "本次变化量（m）",
                            prop: "BCBHL",
                        },
                        {
                            label: "阶段变化量（m）",
                            prop: "JDBHL",
                        },
                    ],
                    // 操作列
                    columnsBtn: {
                        buttonData: [
                            // {
                            //   isShow: false,
                            //   btnType: "icon",
                            //   operateType: "columnEdit",
                            //   title: "编辑",
                            //   icon: "el-icon-edit",
                            //   color: "green"
                            // },
                            // {
                            //   isShow: false,
                            //   btnType: "icon",
                            //   operateType: "columnDelete",
                            //   title: "删除",
                            //   icon: "el-icon-delete",
                            //   color: "red"
                            // }
                        ],
                    },
                },
            },
            snPage: {
                tiaoshu: 10,
                count: 0,
                currentPage: 1,
                operateType: "page",
            },
            snCount: {
                eachtotal: [10, 50, 100],
                defaultTiaoshu: 10,
            },
            snSearch: {
                inputData: [
                    {
                        label: "关键字",
                        placeholder: "请输入关键字",
                        value: "keyword",
                        operateType: "search",
                        isShow: true,
                    },
                ],
                selectData: [
                    {
                        label: "监测区域",
                        clearable: false,
                        placeholder: "请选择监测区域",
                        list: [],
                        optionLabel: "JCQY",
                        optionValue: "JCQY",
                        value: "jcqy",
                        operateType: "searchJcqy",
                        isShow: true,
                    },
                    {
                        label: "病害编号",
                        clearable: false,
                        placeholder: "请选择病害编号",
                        list: [],
                        optionLabel: "BHBH",
                        optionValue: "BHBH",
                        value: "bhbh",
                        operateType: "searchBhbh",
                        isShow: true,
                    },
                ],
                searchBtn: {
                    buttonData: [
                        {
                            btnType: "button",
                            operateType: "searchBtn",
                            name: "查询",
                            round: true,
                            backColor: "#2a63d5",
                            color: "#fff",
                        },
                    ],
                },
            },
            snButton: {
                buttonData: [
                    {
                        isShow: false,
                        btnType: "button",
                        operateType: "buttonDel",
                        name: "批量删除(测试用)",
                        round: true,
                        backColor: "#fa6f1b",
                    },
                    {
                        isShow: true,
                        btnType: "button",
                        operateType: "buttonImport",
                        name: "导入",
                        round: true,
                        backColor: "#28ccd9",
                    },
                    {
                        btnType: "button",
                        operateType: "buttonExport",
                        name: "导出",
                        round: true,
                        backColor: "#ffcc66",
                        color: "#fff",
                    },
                ],
            },
            count: 0,
            tiaoshu: 20,
            currentPage: 1,

            keyword: "",
            typeId: 4,
            typeList: [
                {
                    id: 4,
                    name: "沉降数据",
                },
                {
                    id: 5,
                    name: "位移数据",
                },
                {
                    id: 6,
                    name: "裂缝数据",
                },
            ],
            jcqy: "", // 监测区域
            jcqyList: [],
            bhbh: "", // 病害编号
            bhbhList: [],
            jsdw: "", //建设单位
            yqlx: "", //仪器类型
            gcz: "", //观察者
            jsz: "", //计算者
            jcz: "", //检查者
            dialogData: {
                title: "上传附件",
                dialog: false,
                width: "400px",
                action: false,
            },
            uploadURL: window.REQUEST_URL,
            fileList: [],
        };
    },
    components: {},
    mounted() {
        this.getQyList();
    },
    methods: {
        ...mapActions([
            "GetChbgJcqy",
            "GetChbgCdbh",
            "GetChbgDataList",
            "ExportBasicData",
        ]),
        ...mapActions([]),
        handleChange(type, value) {
            switch (type) {
                case "searchBtn": //查询
                    break;
                case "total": // 每页的条数
                    this[value[1]] = value[0];
                    this.snPage.tiaoshu = value[0];
                    this.snPage.currentPage = 1;
                    this.getMainData();
                    break;
                case "page": // 当前是第几页
                    this.snPage.currentPage = value[0];
                    this.getMainData();
                    break;
                case "buttonImport": // 导入
                    this.dialogData.dialog = true;
                    break;
                case "buttonExport": //导出
                    this.exportFile();
                    break;
            }
        },
        changeTab(item) {
            this.typeId = item.id;
            this.getQyList();
            if (item.id == 4 || item.id == 6) {
                this.snTable.columns.columnsData = [
                    {
                        label: "监测区域",
                        prop: "JCQY",
                    },
                    {
                        label: "病害名称",
                        prop: "SJBHMC",
                    },
                    {
                        label: "测点编号",
                        prop: "CDBH",
                    },
                    {
                        label: "监测时间",
                        prop: "GCSJ",
                    },
                    {
                        label: "本期观测值（m）",
                        prop: "BQGCZ",
                    },
                    {
                        label: "上期观测值（m）",
                        prop: "SQGCZ",
                    },
                    {
                        label: "初始观测值（m）",
                        prop: "CSGCZ",
                    },
                    {
                        label: "本次变化量（m）",
                        prop: "BCBHL",
                    },
                    {
                        label: "阶段变化量（m）",
                        prop: "JDBHL",
                    },
                ];
            } else {
                this.snTable.columns.columnsData = [
                    {
                        label: "监测区域",
                        prop: "JCQY",
                    },
                    {
                        label: "测点编号",
                        prop: "CDBH",
                    },
                    {
                        label: "监测时间",
                        prop: "GCSJ",
                    },
                    {
                        label: "初始观测值X（m）",
                        prop: "CSGCZX",
                    },
                    {
                        label: "初始观测值Y（m）",
                        prop: "CSGCZY",
                    },
                    {
                        label: "上期观测值X（m）",
                        prop: "SQGCZX",
                    },
                    {
                        label: "上期观测值Y（m）",
                        prop: "SQGCZY",
                    },
                    {
                        label: "本期观测值X（m）",
                        prop: "BQGCZX",
                    },
                    {
                        label: "本期观测值Y（m）",
                        prop: "BQGCZY",
                    },
                    {
                        label: "本次水平位移变化值X（mm）",
                        prop: "BCSPWYBHZX",
                    },
                    {
                        label: "本次水平位移变化值Y（mm）",
                        prop: "BCSPWYBHZY",
                    },
                    {
                        label: "累计水平位移变化值X（mm）",
                        prop: "LJSPWYBHZX",
                    },
                    {
                        label: "累计水平位移变化值Y（mm）",
                        prop: "LJSPWYBHZY",
                    },
                ];
            }
        },
        // 获取区域列表
        async getQyList() {
            let result = await this.GetChbgJcqy({
                lx: this.typeId,
            });
            this.jcqyList = result;
            this.jcqy = result[0].JCQY;
            this.getBhbhList();
        },
        // 获取病害编号列表
        async getBhbhList() {
            let result = await this.GetChbgCdbh({
                lx: this.typeId,
                jcqy: this.jcqy,
            });
            this.bhbhList = result;
            if (result.length) {
                this.bhbh = result[0].BHBH;
                this.getMainData();
            } else {
                this.bhbh = "";
            }
        },
        async getMainData() {
            if (!this.jcqy || !this.bhbh) {
                this.common.showMsg("请选择监测区域或病害编号", "warning");
                return;
            }
            let result = await this.GetChbgDataList({
                bgid: this.$route.query.bgid,
                lx: this.typeId,
                keyWords: this.keyword,
                jcqy: this.jcqy,
                bhbh: this.bhbh,
                pageIndex: this.snPage.currentPage,
                pageSize: this.snPage.tiaoshu,
            });
            this.snTable.table.data = result.DATA ? result.DATA : [];
            this.snPage.count = result.TOTAL;
            if (result.JL.length) {
                this.jsdw = result.JL[0].JSDW; //建设单位
                this.yqlx = result.JL[0].YQLX; //仪器类型
                this.gcz = result.JL[0].GCZ; //观察者
                this.jsz = result.JL[0].JSZ; //计算者
                this.jcz = result.JL[0].JCZ; //检查者
            } else {
                this.jsdw = ""; //建设单位
                this.yqlx = ""; //仪器类型
                this.gcz = ""; //观察者
                this.jsz = ""; //计算者
                this.jcz = ""; //检查者
            }
        },
        closeDialog() {
            this.dialogData.dialog = false;
        },
        successUpload(response, file, fileList) {
            this.fileList = [];
            if (response.ISSUCCESS) {
                this.common.showMsg("上传成功", "success");
            } else {
                this.common.showMsg("上传失败", "warning");
            }
        },
        // 导出附件
        async exportFile() {
            if (!this.snTable.table.data.length) {
                this.common.showMsg("暂无数据", "warning");
                return;
            }
            let itemId = "";
            let name = "";
            switch (this.typeId) {
                case 4: //沉降
                    itemId = "chbg_jbczwy";
                    name = "沉降";
                    break;
                case 5: //水平位移
                    itemId = "chbg_jbspwy";
                    name = "位移";
                    break;
                case 6: //裂缝
                    itemId = "chbg_jblf";
                    name = "裂缝";
                    break;
            }
            let str = `${name} - ${this.jcqy} - ${this.bhbh}.xls`;
            let obj = {
                url: window.REQUEST_URL + "GatherPart/ExportBasicData",
                name: str,
                para: {
                    itemId: itemId,
                },
            };
            this.common.exportFile(obj);
        },
        back() {
            this.$router.push({
                path: "/report_manage_grid",
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.mainPart {
    position: relative;
    height: 862px;
    .tabPart {
        padding-top: 20px;
        border-bottom: 1px solid #336799;
        .tabItem {
            width: 130px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color: #336799;
            font-weight: 700;
        }
        .tabItemActive {
            color: #fff;
            background: url("~@image/analysisEvaluation/tab.png");
        }
    }
    .searchPart {
        padding: 10px 0;
        border-bottom: 1px solid #dfe3eb;
        .searchItem {
            margin: 0 10px;
        }
    }
    .actionPart {
        height: 50px;
    }
    .titlePart {
        border: 1px solid #dfe3eb;
        .top {
            height: 30px;
            line-height: 30px;
            border-bottom: 1px solid #dfe3eb;
            padding-left: 10px;
        }
        .bottom {
            height: 30px;
            line-height: 30px;
            .bottomItem {
                border-right: 1px solid #dfe3eb;
                padding-left: 10px;
            }
        }
    }
    .tablePart {
        top: 220px !important;
    }
}
</style>
